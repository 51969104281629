import LoadingBars from '$components/loading-bars/loading-bars.react';
import { useQuery } from '$lib/hooks/fetch-utillities';
import {
  ContactManagerDetailsDocument,
  ContactManagerDetailsQuery
} from '$typings/graphql-codegen';
import React, { FC, memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ContactManagerFormCard from '../components/contactmanager-form-card';
import { useContextContactManager } from '../contactmanager.react';
import { useIsMobile } from '$lib/hooks/isMobile';

interface IContactManagerEditProps {}

const ContactManagerEdit: FC<IContactManagerEditProps> = () => {
  const { contactId } = useParams<string>();
  const [contact, setContact] = useState<
    ContactManagerDetailsQuery['contact']
  >();
  const { navigateToDetails } = useContextContactManager();
  const isMobile = useIsMobile();

  const { data, loading } = useQuery(ContactManagerDetailsDocument, {
    contactId: contactId
  });

  useEffect(() => {
    if (data && data.contact) {
      setContact(data.contact);
    }
  });

  const onCancelClick = () => {
    if (data && data.contact) navigateToDetails(data.contact.contactId);
  };

  const onSaveClick = (contactId: string) => {
    navigateToDetails(contactId);
  };

  if (loading) return <LoadingBars />;

  return (
    <div className={`grow ${!isMobile && 'pt-4 pr-4'}`}>
      {contact && (
        <ContactManagerFormCard
          contact={contact}
          isCreate={false}
          onCancelClick={onCancelClick}
          onSaveClick={onSaveClick}
          className={`${isMobile && 'h-full flex flex-col justify-between'}`}
        />
      )}
    </div>
  );
};

export default memo(ContactManagerEdit);
