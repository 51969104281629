import classNames from 'classnames';
import './avatar-image.react.css';
import React, { FC, memo } from 'react';

interface IAvatarImageReactProps {
  url: string | undefined;
  alt?: string;
  className?: string;
}

const AvatarImageReact: FC<IAvatarImageReactProps> = ({
  url,
  alt,
  className
}) => {
  return (
    <img
      className={classNames(className, 'avatar-image')}
      alt={alt}
      src={url}
    />
  );
};

export default memo(AvatarImageReact);
