import Icon from '$components/icons/icon/icon.react';
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import {
  hasAccessToBuidId,
  hasAccessToCustomerId
} from '$lib/authorizationHelper';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { ContactManagerDetailsQuery } from '$typings/graphql-codegen';
import React, { FC, memo, useEffect, useState } from 'react';
import { getSession, getUserFeatures } from '../../../config/sessionService';
import AvatarImageReact from '$components/avatar-image/avatar-image.react';
import { Card, CardContent } from '@mui/material';

interface IContactManagerDetailsCardProps {
  contact: ContactManagerDetailsQuery['contact'];
  onDeleteClick: () => void;
  onEditClick: () => void;
}

const ContactManagerDetailsCard: FC<IContactManagerDetailsCardProps> = ({
  contact,
  onEditClick,
  onDeleteClick
}) => {
  const [
    hasAccessToUserInUserManager,
    setHasAccessToUserInUserManager
  ] = useState(false);
  const [t] = useCaseInsensitiveTranslation();
  const features = getUserFeatures();
  const session = getSession();

  useEffect(() => {
    if (contact) {
      const access = () => {
        const user = contact?.user;
        if (!user) return false;
        if (!features.manageUsers.read) return false;

        if (user?.buidId && hasAccessToBuidId(session, user.buidId))
          return true;
        if (user?.customerId && hasAccessToCustomerId(session, user.customerId))
          return true;

        return false;
      };

      setHasAccessToUserInUserManager(access());
    }
  }, [contact]);

  return (
    <>
      {contact && (
        <Card>
          <CardContent className="flex justify-between bg-gray-50">
            <div className="flex grow">
              <AvatarImageReact alt="Avatar" url={contact.avatarUrl!} />
              <div className="pl-4">
                {contact.name ? (
                  <header className="text-xxl">{contact.name}</header>
                ) : (
                  <header className="text-xxl">
                    {t('UI_Contacts_NoName')}
                  </header>
                )}
                <p>{contact.email}</p>
                <p>{contact.telephoneNumber}</p>
              </div>
            </div>

            <div className="justify-between flex-nowrap">
              {features.contactManager.delete && (
                <Icon
                  onClick={onDeleteClick}
                  className="clickable"
                  name="fa-trash"
                />
              )}

              {features.contactManager.write && (
                <Icon
                  onClick={onEditClick}
                  className="pl-4 clickable"
                  name="fa-pencil"
                />
              )}
            </div>
          </CardContent>

          <CardContent>
            <SimpleTable>
              <SimpleTableRow>
                <SimpleTableCell>{t('UI_Name')}</SimpleTableCell>
                {contact.name ? (
                  <SimpleTableCell>{contact.name}</SimpleTableCell>
                ) : (
                  <SimpleTableCell>{t('UI_Contacts_NoName')}</SimpleTableCell>
                )}
              </SimpleTableRow>
              <SimpleTableRow>
                <SimpleTableCell>{t('UI_Email')}</SimpleTableCell>
                <SimpleTableCell>
                  <a href={`mailto:${contact.email}`}>{contact.email}</a>
                </SimpleTableCell>
              </SimpleTableRow>
              <SimpleTableRow>
                <SimpleTableCell>{t('UI_Common_Telephone')}</SimpleTableCell>
                <SimpleTableCell>
                  <a href={`tel:${contact.telephoneNumber}`}>
                    {contact.telephoneNumber}
                  </a>
                </SimpleTableCell>
              </SimpleTableRow>
              <SimpleTableRow>
                <SimpleTableCell>{t('UI_Common_Description')}</SimpleTableCell>
                <SimpleTableCell>{contact.description}</SimpleTableCell>
              </SimpleTableRow>
              <SimpleTableRow>
                <SimpleTableCell>{t('UI_Common_Buid')}</SimpleTableCell>
                <SimpleTableCell>{contact.buid?.name}</SimpleTableCell>
              </SimpleTableRow>
              <SimpleTableRow>
                <SimpleTableCell>{t('UI_Common_Customer')}</SimpleTableCell>
                <SimpleTableCell>{contact.customer?.name}</SimpleTableCell>
              </SimpleTableRow>
              {hasAccessToUserInUserManager && (
                <SimpleTableRow>
                  <SimpleTableCell>{t('UI_Common_User')}</SimpleTableCell>
                  <SimpleTableCell>
                    {contact.userId ? (
                      <a
                        className="underline"
                        href={`/usermanager/details/${contact.userId}`}
                      >
                        {contact.user?.name} ({contact.userId})
                      </a>
                    ) : (
                      <span>{contact.user?.name} (Deleted)</span>
                    )}
                  </SimpleTableCell>
                </SimpleTableRow>
              )}
            </SimpleTable>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default memo(ContactManagerDetailsCard);
