import BuidDropdown from '$components/dropdowns/buid-dropdown/buid-dropdown.react';
import CustomerDropdown from '$components/dropdowns/customer-dropdown/customer-dropdown.react';
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import TextField from '$components/textbox/text-field.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { IGeneralFormError } from '$lib/hooks/reactHookFormHelper';
import React, { FC, memo } from 'react';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import {
  ContactManagerDetailsQuery,
  UpsertContactDocument
} from '$typings/graphql-codegen';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { mutate } from '$lib/hooks/fetch-utillities';
import { ensureNumber } from '$lib/numberHelpers';
import ErrorText from '$components/texts/error-text/error-text.react';
import './contactmanager-form-card.css';
import { Button, Card, CardContent } from '@mui/material';
import { useIsMobile } from '$lib/hooks/isMobile';
import classNames from 'classnames';
import Icon from '$components/icons/icon/icon.react';

interface ContactManagerFormType extends IGeneralFormError {
  contactId: string | undefined;
  name: string;
  email: string;
  telephoneNumber: string;
  description: string;
  buidId: string | undefined;
  customerId: string | undefined;
}

interface IContactManagerFormCardProps {
  contact?: ContactManagerDetailsQuery['contact'];
  isCreate?: boolean;
  onSaveClick: (contactId: string) => void;
  onCancelClick: () => void;
  className?: string;
}

const ContactManagerFormCard: FC<IContactManagerFormCardProps> = ({
  contact,
  isCreate,
  onSaveClick,
  onCancelClick,
  className
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const isMobile = useIsMobile();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
    control
  } = useForm<ContactManagerFormType>({
    values: {
      contactId: contact?.contactId,
      buidId: contact?.buid?.buidId ?? undefined,
      customerId: contact?.customer?.customerId ?? undefined,
      description: contact?.description ?? '',
      email: contact?.email ?? '',
      name: contact?.name ?? '',
      telephoneNumber: contact?.telephoneNumber ?? '',
      formError: ''
    }
  });

  const onSubmit: SubmitHandler<ContactManagerFormType> = async data => {
    await mutate(
      UpsertContactDocument,
      {
        contactInput: {
          userId: contact?.userId,
          contactId: data.contactId,
          name: data.name,
          customerId: ensureNumber(data.customerId!),
          buidId: ensureNumber(data.buidId!),
          description: data.description,
          email: data.email,
          telephoneNumber: data.telephoneNumber,
          syncedWithUserInformation: false
        }
      },
      true,
      res => {
        onSaveClick(res.upsertContact!.contactId);
      },
      () => {
        setError('formError', {
          type: 'value',
          message: t('UI_Common_Error_Save_Item_Failed')
        });
      }
    );
  };

  return (
    <Card className={classNames(className)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          {!isMobile && (
            <h1 className="m-0 text-xxl">
              {isCreate
                ? t('UI_Contactmanager_ContactInfo_CreateContact_Header')
                : t('UI_Contactmanager_ContactInfo_EditContact_Header')}
            </h1>
          )}

          <SimpleTable>
            <SimpleTableRow>
              <SimpleTableCell className={`vertical-align-middle`}>
                {t('UI_Name')}
              </SimpleTableCell>
              <SimpleTableCell>
                <TextField {...register('name')} />
              </SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow>
              <SimpleTableCell className="vertical-align-middle">
                {t('UI_Email')}
              </SimpleTableCell>
              <SimpleTableCell>
                <TextField {...register('email')} />
              </SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow>
              <SimpleTableCell className="vertical-align-middle">
                {t('UI_Common_Telephone')}
              </SimpleTableCell>
              <SimpleTableCell>
                <TextField {...register('telephoneNumber')} />
              </SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow>
              <SimpleTableCell className="vertical-align-middle">
                {t('UI_Common_Description')}
              </SimpleTableCell>
              <SimpleTableCell>
                <TextField {...register('description')} />
              </SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow>
              <SimpleTableCell className="vertical-align-middle">
                {t('UI_Common_Buid')}
              </SimpleTableCell>
              <SimpleTableCell>
                <Controller
                  control={control}
                  name="buidId"
                  rules={{
                    required: {
                      value: true,
                      message: t(
                        'ui_common_validationerror_requiredfield'
                      ) as string
                    }
                  }}
                  render={({ field }) => (
                    <BuidDropdown
                      placeholder={'Select buid'}
                      className="dropdown"
                      selected={field.value}
                      error={!(errors.buidId as FieldError)?.message}
                      itemClicked={({ buidId }) => {
                        field.onChange(buidId);
                      }}
                    />
                  )}
                />
                <ErrorText>{(errors.buidId as FieldError)?.message}</ErrorText>
              </SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow>
              <SimpleTableCell className="vertical-align-middle">
                {t('UI_Common_Customer')}
              </SimpleTableCell>
              <SimpleTableCell>
                <Controller
                  control={control}
                  name="customerId"
                  rules={{
                    required: {
                      value: true,
                      message: t(
                        'ui_common_validationerror_requiredfield'
                      ) as string
                    }
                  }}
                  render={({ field }) => (
                    <CustomerDropdown
                      placeholder={'Select customer'}
                      className="dropdown"
                      selected={field.value}
                      itemClicked={({ customerId }) => {
                        field.onChange(customerId);
                      }}
                    />
                  )}
                />
                <ErrorText>
                  {(errors.customerId as FieldError)?.message}
                </ErrorText>
              </SimpleTableCell>
            </SimpleTableRow>
          </SimpleTable>
        </CardContent>
        <CardContent className="flex row basic-info justify-between">
          <Button onClick={onCancelClick} variant="outlined" color="primary">
            {t('UI_Common_Cancel')}
          </Button>
          <Button
            startIcon={isSubmitting && <Icon name="fa-spinner fa-pulse" />}
            variant="contained"
            type="submit"
          >
            {t('UI_Common_SaveChanges')}
          </Button>
        </CardContent>
      </form>
    </Card>
  );
};

export default memo(ContactManagerFormCard);
