import {
  ContactManagerDetailsDocument,
  DeleteContactDocument
} from '$typings/graphql-codegen';
import { useParams } from 'react-router-dom';
import React, { FC, memo, useState } from 'react';
import { mutate, useQuery } from '$lib/hooks/fetch-utillities';
import { useContextContactManager } from '../contactmanager.react';
import RequireConfirmation from '$components/require-confirmation/require-confirmation.react';
import ContactmanagerDetailsCard from '../components/contactmanager-details-card';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import Skeleton from '$components/placeholders/skeleton/skeleton';
import { Card, CardContent } from '@mui/material';
import { useIsMobile } from '$lib/hooks/isMobile';

interface IContactManagerDetailsProps {}

const ContactManagerDetails: FC<IContactManagerDetailsProps> = ({}) => {
  const { navigateToEdit, navigateToList } = useContextContactManager();
  const [t] = useCaseInsensitiveTranslation();
  const { contactId } = useParams<string>();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const isMobile = useIsMobile();

  const { data } = useQuery(ContactManagerDetailsDocument, {
    contactId: contactId
  });

  const handleDelete = async () => {
    await mutate(
      DeleteContactDocument,
      {
        contactId: contactId
      },
      true,
      () => {
        navigateToList();
      },
      () => {
        setError(t('ui_common_error_save_item_failed'));
      }
    );
  };

  const onDeleteClick = async () => {
    setShowDeleteConfirmation(true);
  };

  const onEditClick = async () => {
    if (data && data.contact) navigateToEdit(data.contact.contactId);
  };

  return (
    <div className={`grow pt-4 pr-4 ${isMobile && 'p-4'}`}>
      {data && data.contact ? (
        <ContactmanagerDetailsCard
          contact={data.contact}
          onDeleteClick={onDeleteClick}
          onEditClick={onEditClick}
        />
      ) : (
        <Card>
          <CardContent className="row basic-info">
            <Skeleton rowCount={2} />
          </CardContent>
          <CardContent>
            <Skeleton rowCount={6} />
          </CardContent>
        </Card>
      )}
      {showDeleteConfirmation && (
        <RequireConfirmation
          entityName={data?.contact?.name}
          onConfirm={handleDelete}
          onCancel={() => {
            setShowDeleteConfirmation(false);
          }}
          error={error}
        />
      )}
    </div>
  );
};

export default memo(ContactManagerDetails);
