import { ReactWrapper } from '$pages/common/react-wrapper';
import ContactManager, { IContactManagerProps } from './contactmanager.react';

export class ContactManagerReactWrapper extends ReactWrapper<
  IContactManagerProps
> {
  constructor() {
    super(ContactManager);
  }
}
