import React, { FC, memo, useMemo } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Outlet,
  useNavigate,
  Navigate,
  useOutletContext,
  useMatch
} from 'react-router-dom';
import ContactManagerList from './components/contactmanager-list';
import ContactManagerDetails from './contactmanager-details/contactmanager-details';
import ContactManagerEdit from './contactmanager-edit/contactmanager-edit';
import ContactManagerCreate from './contactmanager-create/contactmanager-create';
import { useIsMobile } from '$lib/hooks/isMobile';

enum PageType {
  ContactManagerList = '',
  ContactManagerCreate = 'create',
  ContactManagerDetails = 'details/',
  ContactManagerEdit = 'edit/'
}

type ContextContactManagerType = {
  navigateToEdit: (contactId: string) => void;
  navigateToDetails: (contactId: string) => void;
  navigateToList: () => void;
};

export interface IContactManagerProps {}

const ContactManagerComponent: FC<IContactManagerProps> = ({}) => {
  const contactManagerPath = 'contactmanager';

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<Navigate to={contactManagerPath} />}>
        <Route path={contactManagerPath} element={<ContactManagerGrid />}>
          <Route
            index
            path={PageType.ContactManagerDetails + ':contactId'}
            element={<ContactManagerDetails />}
          />
          <Route
            index
            path={PageType.ContactManagerEdit + ':contactId'}
            element={<ContactManagerEdit />}
          />
          <Route
            index
            path={PageType.ContactManagerCreate}
            element={<ContactManagerCreate />}
          />
        </Route>
      </Route>
    )
  );
  return <RouterProvider router={router} />;
};

export default memo(ContactManagerComponent);

interface IContactManagerGridProps {}

const ContactManagerGrid: FC<IContactManagerGridProps> = memo(
  ({}: IContactManagerGridProps) => {
    const nav = useNavigate();
    const isMobile = useIsMobile();
    const isOnContactList = useMatch('contactmanager');
    const showContactList = useMemo(() => {
      if (isMobile) {
        return isOnContactList;
      }
      return true;
    }, [isMobile, isOnContactList]);

    const setActivePageAndNavigate = (
      page: PageType,
      contactId: string = ''
    ) => {
      nav(page + contactId);
    };

    const navigateToEdit = (contactId: string) => {
      setActivePageAndNavigate(PageType.ContactManagerEdit, contactId);
    };

    const navigateToDetails = (contactId: string) => {
      setActivePageAndNavigate(PageType.ContactManagerDetails, contactId);
    };

    const navigateToList = () => {
      setActivePageAndNavigate(PageType.ContactManagerList);
    };

    return (
      <div className="flex flex-row h-full">
        {showContactList && (
          <ContactManagerList
            onContactClick={(contactId: string) => {
              setActivePageAndNavigate(
                PageType.ContactManagerDetails,
                contactId
              );
            }}
            onContactCreateClick={() => {
              setActivePageAndNavigate(PageType.ContactManagerCreate);
            }}
          />
        )}

        <Outlet
          context={
            {
              navigateToDetails,
              navigateToEdit,
              navigateToList
            } as ContextContactManagerType
          }
        />
      </div>
    );
  }
);

export function useContextContactManager() {
  return useOutletContext<ContextContactManagerType>();
}
