import './contactmanager-list.css';
import { useGridData } from '$components/grid/react-grid/gridhelper';
import {
  Contact,
  ContactManagerListDocument,
  ContactSortEnum,
  SortDirection
} from '$typings/graphql-codegen';
import React, { FC, memo, useRef, useState } from 'react';
import { ListRange, Virtuoso } from 'react-virtuoso';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import Dropdown from '$components/dropdowns/dropdown/dropdown.react';
import Icon from '$components/icons/icon/icon.react';
import { getUserFeatures } from '../../../config/sessionService';
import { Button, Card, Skeleton } from '@mui/material';
import AvatarImageReact from '$components/avatar-image/avatar-image.react';
import TextField from '$components/textbox/text-field.react';

interface IContactManagerListProps {
  onContactClick: (contactId: string) => void;
  onContactCreateClick: () => void;
}

const ContactManagerList: FC<IContactManagerListProps> = ({
  onContactClick,
  onContactCreateClick
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const lastViewedItems = useRef<ListRange>();
  const [freeTextQuery, setFreeTextQuery] = useState<string>('');
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [selectedSort, setSelectedSort] = useState<ContactSortEnum>(
    ContactSortEnum.Name
  );
  const [variables, setVariables] = useState({
    freeTextQuery: freeTextQuery,
    sortProperty: selectedSort,
    sortDirection: SortDirection.Asc,
    offset: 0,
    first: 50
  });
  const features = getUserFeatures();

  const onVariablesUpdated = () => {
    setVariables(prev => ({
      ...prev,
      freeTextQuery: freeTextQuery,
      sortProperty: selectedSort
    }));
  };

  const data = useGridData(
    ContactManagerListDocument,
    variables,
    data => data?.contacts.totalCount,
    data => data.contacts.edges
  );

  return (
    <div id="contactmanagerlist" className="h-full">
      <div className="flex">
        <div className={`grow ${!isSearching && 'contactmanagerlist-search'}`}>
          <TextField
            onChange={e => {
              setFreeTextQuery(e.target.value);
              onVariablesUpdated();
            }}
            value={freeTextQuery}
            icon={<Icon name="fa-search"></Icon>}
            type="text"
            onBlur={() => setIsSearching(false)}
            onFocus={() => setIsSearching(true)}
            placeholder={isSearching ? 'Search for contact' : ''}
          />
        </div>
        {!isSearching && (
          <>
            <div className="grow flex row">
              <Icon name="fa-sort" className="contactmanager-icon" />
              <Dropdown<ContactSortEnum>
                className="grow contactmanagerlist-dropdown"
                selected={selectedSort}
                items={Object.values(ContactSortEnum)}
                itemClicked={item => {
                  setSelectedSort(item?.value as ContactSortEnum);
                  onVariablesUpdated();
                }}
                searchable={false}
                translate={true}
              />
            </div>
          </>
        )}
      </div>
      <Virtuoso
        overscan={50}
        data={data.data}
        components={{
          EmptyPlaceholder: () => (
            <div className="loading">
              {data.isRevalidating && !data.data ? (
                <Skeleton />
              ) : (
                <span>{t('ui_grid_nomatch')}</span>
              )}
            </div>
          )
        }}
        rangeChanged={i => {
          if (i.startIndex > 0) {
            data.visibleItemsChanged(i);
          }
          lastViewedItems.current = i;
        }}
        itemContent={(_index, item: Contact) => {
          return (
            <div onClick={() => onContactClick(item.contactId)}>
              <Card className="contactmanager-card clickable m-1">
                <div className="basic-info">
                  {item ? (
                    <>
                      <AvatarImageReact
                        className="image"
                        alt="Avatar"
                        url={item.avatarUrl!}
                      />
                      <div className="text">
                        {item.name ? (
                          <header className="name">{item.name}</header>
                        ) : (
                          <header className="noname">
                            {t('UI_Contacts_NoName')}
                          </header>
                        )}
                        <div className="email">{item.email}</div>
                        <div className="email">{item.telephoneNumber}</div>
                      </div>
                    </>
                  ) : (
                    <>
                      <Skeleton className="image" />
                      <Skeleton className="text" />
                    </>
                  )}
                </div>
              </Card>
            </div>
          );
        }}
      />
      {features.contactManager.write && (
        <Button
          className="pt-2"
          color="primary"
          variant="contained"
          onClick={() => onContactCreateClick()}
        >
          {t('UI_Contactmanager_List_AddNewContact')}
        </Button>
      )}
    </div>
  );
};

export default memo(ContactManagerList);
