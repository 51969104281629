import React, { FC, memo } from 'react';
import ContactManagerFormCard from '../components/contactmanager-form-card';
import { useContextContactManager } from '../contactmanager.react';
import { useIsMobile } from '$lib/hooks/isMobile';

interface IContactManagerCreateProps {}

const ContactManagerCreate: FC<IContactManagerCreateProps> = () => {
  const { navigateToDetails, navigateToList } = useContextContactManager();
  const isMobile = useIsMobile();

  const onCancelClick = () => {
    navigateToList();
  };

  const onSaveClick = (contactId: string) => {
    navigateToDetails(contactId);
  };

  return (
    <div className={`grow ${!isMobile && 'pt-4 pr-4'}`}>
      <ContactManagerFormCard
        isCreate={true}
        onCancelClick={onCancelClick}
        onSaveClick={onSaveClick}
        className={`${isMobile && 'h-full flex flex-col justify-between'}`}
      />
    </div>
  );
};

export default memo(ContactManagerCreate);
